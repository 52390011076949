import IPQS from 'node_js_ipqs_device_tracker';
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import { UAParser } from 'ua-parser-js';
import Cookies from 'js-cookie';
import config from '~/config';

type VENDOR = 'ipqs' | 'fingerprintjs';

type DeviceFingerprintRequest = {
  vendor: VENDOR;
  request_id?: string;
  device_id?: string;
  operating_system?: string;
  browser?: string;
  platform?: string;
};

type DeviceFingerprintResponse = {
  data: {
    id: string;
  };
  success: boolean;
};

type CheckIpResponse = {
  data: {
    is_blocked: boolean;
  };
  success: boolean;
};

type IPQSResponse = {
  message: string;
  success: boolean;
  guid: string;
  guid_confidence: number;
  device_id: string;
  unique: boolean;
  is_crawler: boolean;
  fraud_chance: number;
  proxy: boolean;
  vpn: boolean;
  tor: boolean;
  time: number;
  country: string;
  city: string;
  recent_abuse: boolean;
  active_vpn: boolean;
  active_tor: boolean;
  ip_address: string;
  connection_type: string;
  request_id: string;
};

const FP_KEY = 'ct-idfp';
const FP_EXPIRES = 400; // 400 days
const TRACKER = '*';
const DOMAIN = `idfp.chotot.${config.baseDomain}`;

let getDeviceIdPromise: Promise<string> | null = null;

const getCookieDomain = (hostname: string) => {
  if (!hostname) {
    return '';
  }

  if (!hostname.includes('.')) {
    return hostname;
  }

  const subNames = hostname.split('.');
  const mainNames = subNames.slice(-2);

  return `.${mainNames.join('.')}`;
};

const getFP = () => Cookies.get(FP_KEY);
const setFP = (fp: string) =>
  Cookies.set(FP_KEY, fp, {
    expires: FP_EXPIRES,
    domain: getCookieDomain(window.location.hostname),
  });

const fetchDeviceFingerprint = async (
  request: DeviceFingerprintRequest
): Promise<DeviceFingerprintResponse> => {
  const { vendor, ...rest } = request;
  const body = {
    raw_data: rest,
    vendor,
  };

  const res = await fetch(`${config.gatewayUrl}/api-uni/public/uni-user/device-fingerprint`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const data = await res.json();

  if (!res.ok) throw data;

  return data;
};

const fetchCheckIp = async () => {
  const res = await fetch(`${config.gatewayUrl}/api-uni/public/uni-user/check-ip`, {
    method: 'POST',
  });
  if (!res.ok) return false;

  const data = (await res.json()) as CheckIpResponse;
  return data.data.is_blocked;
};

const getFromFingerprintJS = async () => {
  const load = await fingerprintjs.load({ monitoring: false });
  const fingerprint = await load.get();

  return fingerprint;
};

const init = async () => {
  if (getDeviceIdPromise) return;

  getDeviceIdPromise = new Promise<string>(async (resolve) => {
    const fp = getFP();
    if (fp) {
      resolve(fp);
      return;
    }

    const isBlock = await fetchCheckIp();
    if (isBlock) {
      resolve('');
      return;
    }

    const ipqs = new Promise<IPQSResponse>((resolve, reject) => {
      IPQS.initializeScriptAsync(config.ipqsKey, TRACKER, DOMAIN)
        .then(() => {
          // @ts-ignore
          IPQS.AfterResult((result) => {
            resolve(result);
          });

          // @ts-ignore
          IPQS.AfterFailure((err) => {
            reject(err);
          });

          IPQS.Init();
        })
        .catch((err) => reject(err));
    });

    ipqs
      .then(async (result) => {
        try {
          const res = await fetchDeviceFingerprint({
            vendor: 'ipqs',
            request_id: result.request_id,
          });
          setFP(res.data.id);
          resolve(res.data.id);
        } catch (err) {
          resolve('');
        }
      })
      .catch(async () => {
        // fallback case
        try {
          const result = await getFromFingerprintJS();
          const ua = UAParser(navigator.userAgent);
          const res = await fetchDeviceFingerprint({
            vendor: 'fingerprintjs',
            device_id: result.visitorId,
            operating_system: `${ua.os.name} ${ua.os.version}`,
            browser: `${ua.browser.name} ${ua.browser.version}`,
            platform: 'web',
          });
          setFP(res.data.id);
          resolve(res.data.id);
        } catch (err) {
          resolve('');
        }
      });
  });
};

const get = async () => {
  if (!getDeviceIdPromise) throw new Error('Device fingerprint not initialized');

  return getDeviceIdPromise;
};

const exportFunctions = { init, get };

export default exportFunctions;
