import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { loadContainers } from '@chotot/web-gtm';
import getInitProps from '~/helpers/initProps';
import config, { app } from '~/config';
import NextNProgress from '~/components/ProgressBar/ProgressBar';
import fingerprintV2 from '~/helpers/fingerprintV2';

import 'clad-ui/css/baseline';

const renderAppleMetas = () => app.head.meta.map((item) => <meta key={item.key} {...item} />);

class MyApp extends App {
  static getInitialProps = async ({ Component, ctx }) => {
    const appProps = getInitProps(ctx);
    const isServer = !!ctx.req;
    ctx.isClient = !isServer;
    ctx.query = appProps.query;
    ctx.location = appProps.location;
    ctx.config = config;

    const pageProps = Component.getInitialProps ? await Component.getInitialProps({ ...ctx }) : {};

    return {
      pageProps,
      appProps,
    };
  };

  componentDidMount() {
    loadContainers(config.gtmContainerId);
    fingerprintV2.init();
  }

  render() {
    const {
      Component,
      pageProps,
      appProps,
      appProps: { location, query },
      pageProps: { pageName },
    } = this.props;

    const customCSS = `
      .grecaptcha-badge {
        visibility: hidden;
      }
    `;

    return (
      <>
        <Head>
          <title>{config.app.title}</title>
          {renderAppleMetas()}
          <style>{customCSS}</style>
        </Head>
        <NextNProgress />
        <Component {...appProps} {...pageProps} router={{ ...location, query, pageName }} />
      </>
    );
  }
}

export default MyApp;
