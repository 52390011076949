import url from 'url';
import routeConstants from '~/helpers/constants/routes';
import { checkIsMobile } from '~/helpers/util';

const { ROUTE_PARAMS } = routeConstants;

const buildQueryOnClient = (query = {}) => {
  const result = {};
  // remove query duplicate with route params
  const arrayQuery = Object.keys(query).filter((key) => !ROUTE_PARAMS.includes(key));
  arrayQuery.forEach((key) => {
    result[key] = query[key];
  });
  return result;
};

/**
 * @param {import ('next').GetServerSidePropsContext<any> &
 *         import ('next').NextPageContext<any>} context
 */
export const getServerProps = (context) => {
  const { req, asPath } = context;
  const userAgent = req.headers['user-agent'];
  const isMobile = checkIsMobile(userAgent);
  const urlObject = url.parse(asPath);
  return {
    isMobile,
    location: {
      pathname: urlObject.pathname,
    },
    query: req.query,
  };
};

/**
 *
 * @param {import ('next').NextPageContext<any>} context
 */
export const getClientProps = (context) => {
  const { asPath, query } = context;
  const { userAgent } = navigator;
  const isMobile = checkIsMobile(userAgent);
  return {
    isMobile,
    location: {
      pathname: asPath.split('?')[0],
    },
    query: buildQueryOnClient(query),
  };
};

/**
 * @param {import ('next').NextPageContext<any>} context
 */
const getInitProps = (context) => {
  const { pathname, query } = context;
  const isServer = !!context.res;
  const getAppProps = isServer ? getServerProps : getClientProps;

  // keep the prop keys flat here, any nested data object should be done at _app.getInitialProps
  return {
    ...getAppProps(context),
    isServer,
    query,
    // return index if it is root path, return 'pageName' from '/folder/pageName'
    pageName: pathname === '/' ? 'index' : pathname.substr(pathname.lastIndexOf('/') + 1),
  };
};

export default getInitProps;
