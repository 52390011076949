import React, { useEffect, memo } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
const CONFIG = {
  color: '#589F39',
  stopDelayMs: 200,
  startPosition: 0.5,
  height: 3
};
export const progressBarStyles = "p1y0i0ht";
const NextNProgress = memo(() => {
  let timer = null;
  const {
    stopDelayMs,
    startPosition
  } = CONFIG;
  const routeChangeStart = () => {
    NProgress.set(startPosition);
    NProgress.start();
  };
  const routeChangeEnd = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };
  useEffect(() => {
    // if (options) {
    //   NProgress.configure(options);
    // }
    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeEnd);
  }, []);

  // just a hidden progress bar as a placeholder
  return <progress hidden />;
});
export default NextNProgress;

require("./ProgressBar.linaria.module.css!=!../../../node_modules/.pnpm/@linaria+webpack5-loader@4.1.17_webpack@5.83.1/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./ProgressBar.js");