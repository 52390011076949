/* eslint-disable import/no-commonjs */
// @ts-ignore
const refDomain =
  (typeof window !== 'undefined' ? window.REFERRER_DOMAIN : process.env.REFERRER_DOMAIN) ||
  'vieclamtot,nha-tot,nhatot';

/**
 * These environment vars will be available for both server and client.
 * Add more if needed
 *
 * @example
 * import { universalEnv } from '~config';
 * console.log(universalEnv.BASE_DOMAIN);
 *
 * @type {{
 *   BASE_DOMAIN: string,
 *   ENV: string,
 *   GTM_CONTAINER: string,
 *   ASSET_DOMAIN: string,
 *   CAPTCHA_ID: string,
 *   GOOGLE_CLIENT_ID: string,
 *   IPQS_KEY: string,
 * }}
 */
const universalEnv = Object.fromEntries([
  getUniversalEnv('BASE_DOMAIN', 'org'),
  getUniversalEnv('ENV', 'dev'),
  getUniversalEnv('GTM_CONTAINER', 'GTM-NZKHXF7'),
  getUniversalEnv('ASSET_DOMAIN', ''),
  getUniversalEnv('CAPTCHA_ID', '6LcI94gcAAAAADSqpaVECVGhk3imKF7gKSNQHQoI'),
  getUniversalEnv(
    'GOOGLE_CLIENT_ID',
    '5022248495-ppipcr46gh7d65rtf1ogd8p2al7re5gv.apps.googleusercontent.com'
  ),
  getUniversalEnv(
    'IPQS_KEY',
    '05ROgFLuocW7860dxYIiPGx0HLexCQ4GnIAK19K75SyW8ha9oaUcEmApTldKBbKjNYrocCyrfEfGvvRf2bA2HPfg1Crx2lfLrzB4Y1l83cabOJOQdqjc2QCEvmloxHUEbuVQuVS0vOCKEeN328OTOBstjAozVPCeSHFIeGVLGZbdtOXV4HMF9sEmMLEWf5K9UUQLa27b7VEFJnnf2SprhKRZ09z2owQuVhdVWynkOXaTiECJy5sCS1QbRZcM5TBT'
  ),
]);

const baseDomain = universalEnv.BASE_DOMAIN;
const env = universalEnv.ENV;

const assetPrefix =
  process.env.ASSET_DOMAIN && process.env.LATEST_COMMIT
    ? `${process.env.ASSET_DOMAIN}/${process.env.LATEST_COMMIT}`
    : '';

// ** Use this code to fallback for current situation (nhatot, vieclamtot) ** //
// ** If we have any new domain, It's must be set REFERRER_DOMAIN env ** //
const referrerDomainStr = refDomain;
const referrerDomain = referrerDomainStr.split(',');
// ** ** //

module.exports = {
  baseDomain,
  assetPrefix,
  cookieConfig: {
    path: '/',
    domain: process.env.NODE_ENV === 'development' ? 'localhost' : `.chotot.${baseDomain}`,
    expires: 365,
  },
  captcha: universalEnv.CAPTCHA_ID,
  fbAppId: '221564734660253',
  appleServiceId: 'vn.chotot.iosapp.dev.signinapple',
  googleClientId: universalEnv.GOOGLE_CLIENT_ID,
  ipqsKey: universalEnv.IPQS_KEY,
  env,
  baseUrl: `https://www.chotot.${baseDomain}`,
  vehBaseUrl: `https://xe.chotot.${baseDomain}`,
  ptyBaseUrl: `${
    env !== 'prod' ? `https://www.nha-tot.${baseDomain}` : `https://www.nhatot.${baseDomain}`
  }`,
  jobBaseUrl: `https://www.vieclamtot.${baseDomain}`,
  gatewayUrl: `https://gateway.chotot.${baseDomain}`,
  gtmContainerId: universalEnv.GTM_CONTAINER,
  lightAdUrl: `https://www.chotot.${baseDomain}/dang-tin`,
  referrerDomainStr,
  referrerDomain,
  chototId: {
    endpoint: `https://id.chotot.${baseDomain}/api`,
    clientId: 'ct_web_client',
    callback: `https://www.chotot.${baseDomain}`,
  },
  app: {
    title: 'Chợ Tốt - Website Mua Bán, Rao Vặt Trực Tuyến Hàng Đầu Của Người Việt - Chợ Tốt',
    head: {
      meta: [
        {
          key: 'meta_charSet',
          charSet: 'utf-8',
        },
        {
          key: 'meta_name',
          name: 'name',
          content: 'Chợ Tốt - Website Mua Bán, Rao Vặt Trực Tuyến Hàng Đầu Của Người Việt',
        },
        {
          key: 'meta_description',
          name: 'description',
          content:
            'Chợ Tốt - Website mua bán rao vặt của người Việt với hàng ngàn món hời đang được rao bán mỗi ngày. Đăng tin mua bán UY TÍN, NHANH CHÓNG, AN TOÀN.',
        },
        {
          key: 'meta_image',
          property: 'image',
          content: 'https://static.chotot.com/storage/marketplace/ct_orange_c2c_200.jpg',
        },
        {
          key: 'meta_fb_admins',
          property: 'fb:admins',
          content: '100003537963527',
        },
        {
          key: 'meta_fb_app_id',
          property: 'fb:app_id',
          content: '221564734660253',
        },
        {
          key: 'meta_og_title',
          property: 'og:title',
          content: 'Chợ Tốt - Website Mua Bán, Rao Vặt Trực Tuyến Hàng Đầu Của Người Việt',
        },
        {
          key: 'meta_og_image',
          property: 'og:image',
          content: 'https://static.chotot.com/storage/marketplace/ct_orange_c2c_200.jpg',
        },
        {
          key: 'meta_og_description',
          property: 'og:description',
          content:
            'Chợ Tốt - Website mua bán rao vặt của người Việt với hàng ngàn món hời đang được rao bán mỗi ngày. Đăng tin mua bán UY TÍN, NHANH CHÓNG, AN TOÀN.',
        },
        {
          key: 'meta_og_url',
          property: 'og:url',
          content: 'https://www.chotot.com',
        },
        {
          key: 'meta_og_type',
          property: 'og:type',
          content: 'website',
        },
        {
          key: 'meta_viewport',
          name: 'viewport',
          content:
            'width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0',
        },
        {
          key: 'meta_mobile_web_app_capable',
          name: 'mobile-web-app-capable',
          content: 'yes',
        },
        {
          key: 'meta_apple_mobile_web_app_capable',
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        {
          key: 'meta_application_name',
          name: 'application-name',
          content: 'Chotot',
        },
        {
          key: 'meta_apple_mobile_web_app_status_bar_style',
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black',
        },
        {
          key: 'meta_apple_mobile_web_app_title',
          name: 'apple-mobile-web-app-title',
          content: 'Chotot.com',
        },
        {
          key: 'meta_theme_color',
          name: 'theme-color',
          content: '#FFFFFF',
        },
      ],
    },
  },
};

/**
 * @param {string} key The universal key used to access `process.env[key]` or `window[key]`
 * @param {any} [defaultValue] fallback value
 * @param {string} [processEnvKey] server side process.env[key] if we want to use different client env name
 * @return {[string, any]}
 */
function getUniversalEnv(key, defaultValue = '', processEnvKey = '') {
  if (processEnvKey != null && process?.env[processEnvKey] != null) {
    // server side key different from client side global env
    return [key, process.env[processEnvKey]];
  } else if (process?.env[key] != null) {
    // server side
    return [key, process.env[key]];
  }
  if (typeof window !== 'undefined' && window[key] != null) {
    // client side
    return [key, window[key]];
  }
  // fallback
  return [key, defaultValue];
}
